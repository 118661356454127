import React, {Component} from 'react';
import {connect} from "react-redux";
import * as siteActionCreators from "../../store/actions/site";
import style from './mind.module.scss';

class Player extends Component<any, any> {
    name: any = React.createRef();
    tag: any = React.createRef();
    logo: any = React.createRef();
    container: any = React.createRef();

    state = {
        hidden: false
    }

    componentDidMount() {
        setTimeout(() => {

            let name_node: any = this.name.current.querySelectorAll('span');
            let tag_node: any = this.tag.current;
            let logo_node: any = this.logo.current;

            name_node.forEach((node: HTMLSpanElement, i: number) => {
                node.style.transform = `scale(1)`;
                node.style.opacity = String(1);
                node.style.transitionDuration = '150ms';
                node.style.transitionDelay = `${i * 50}ms`;
            });

            let last: number = (name_node.length - 1);
            name_node[last].addEventListener('transitionend', (evt: TransitionEvent) => {
                if (evt.propertyName === 'transform') {
                    tag_node.style.transform = `scale(1)`;
                    tag_node.style.opacity = String(1);
                    tag_node.style.transitionDuration = '300ms';
                    tag_node.style.transitionDelay = `100ms`;

                    tag_node.addEventListener('transitionend', (evt: TransitionEvent) => {
                        if (evt.propertyName === 'opacity') {
                            logo_node.style.transitionDelay = '1s';
                            logo_node.style.transitionDuration = '500ms';
                            logo_node.style.opacity = String(0);
                            logo_node.style.transform = 'translateY(30px)';
                            setTimeout(() => {
                                this.setState({hidden: true}, () => this.props.play_video());
                            }, 1300);
                        }
                    });
                }
            });
        }, 1500);
    }

    render() {
        const {hidden}: any = this.state;
        const {children}: any = this.props;
        return (<>
                {!hidden && <div className={style.MainMind} ref={this.container}>
                    <figure ref={this.logo}>
                        <div ref={this.name}>
                            <span>M</span>
                            <span>i</span>
                            <span>n</span>
                            <span>d</span>
                            <span>B</span>
                            <span>l</span>
                            <span>o</span>
                            <span>w</span>
                            <span>n</span>
                        </div>
                        <label ref={this.tag}>Lab</label>
                    </figure>
                </div>}
                {hidden && children}
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({...state.site});
export default connect(mapStateToProps, siteActionCreators)(Player);