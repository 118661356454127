import React, {Component} from 'react';
import {connect} from "react-redux";
import * as siteActionCreators from "../../store/actions/site";
import Layout from "../../layout";
import style from "./style.module.scss";
import Emitter from "../../utils/emitter";

class Page extends Component<any, any> {

    state = {
        image: null
    }

    componentDidMount() {
        let image: HTMLImageElement = new Image();
        image.onload = () => {
            this.setState({image});
            Emitter.emit('PRELOAD_OFF', true);
        }
        image.src = require(`../../assets/bg_contact.png`);
    }

    render() {
        const {image}: any = this.state;
        return (<Layout {...this.props}>
                <div className={style.MainContact} style={{backgroundImage: `url(${image ? image?.src : ''})`}}>
                    <div>
                        <hgroup>
                            <h1>CONTACT US</h1>
                            <div>
                                Somos uma equipe técnica bem divertida.<br/>
                                Na maior parte do tempo estamos focados em soluções de problemas.<br/>
                                Contudo, temos a obrigação de mantermos o ambiente tão leve quanto um GIF.
                            </div>
                        </hgroup>
                        <section>
                        </section>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state: any) => ({...state.site});
export default connect(mapStateToProps, siteActionCreators)(Page);