export default {
    'management': 'Management',
    'development': 'Development',
    'training': 'Training',

    'contact': 'Contact',
    'partner': 'Partners',

    'project_management': 'Project Management',
    'tech_artist': 'Tech Artist',
    'pos_production': 'Pos-Production',
    'rigging': 'Rigging',

    'full_project': 'Full project',
    'team_management': 'Team Management',
    'tech_supervisor': 'Tech Supervisor',
    'tech_director': 'Tech Director',

    'assembler_project': 'Assembler Project',
    'layout_artist': 'Layout Artist',
    'tech_anim': 'Tech Anim',
    'pipeline': 'Pipeline',

    'composition_3d': 'Composition 3D',
    'composition_2d': 'Composition 2D',
    'composition_live_action': 'Composition Live Action',
    'tracking': 'Tracking',
    'cleanup_footage': 'Cleanup Footage',
    'comp_supervisor': 'Comp Supervisor',

    'character': 'Character',
    'prop': 'Prop',
    'vehicle': 'Vehicle',
    'creature': 'Creature',
    'organic': 'Organic',
    'tool': 'Tool',

    'alembic_io': 'Animation Publish',
    'export_io': 'Export Files',
    'environment': 'Environment',
    'scene_management': 'Scene Management',
    'publish_management': 'Publish Management',
    'publish_camera': 'Camera Publish',
    'management_tools': 'Management Tools',
    'submit_render': 'Universal Submit to Deadline',

    'basic': 'Basic',
    'intermediary': 'Intermediary',
    'advanced': 'Advanced',
    'registration': 'Registration',
    'programming': 'Programming',
    'requireds': 'Requireds',
    'iam': 'I am',
    'studant': 'Student',
    'studio': 'Studio',

    'send':'Submit',
    'management_info': 'Select the fields where we can help and then submit it to us.',

    'copyright': '© Copyright 2021. Mindblown Lab and clients.',
    'cookies': 'This site uses cookies to personalize ads and improve your experience. According to Art. 5 of the LGPD (Law 13.709/2018), we ask for your consent to store and process personal data for the technologies on this website to work. Your rejection may imply in the non-functioning of the technologies of this site. Do you accept session cookies in your browser?'
}