import {createSlice} from "@reduxjs/toolkit";

export const site = createSlice({
    name: 'app',
    initialState: {
        user: null,
        preloader: true
    },
    reducers: {
        setUser: (state: any, action: any) => {
            return {...state, user: action.payload};
        },
        setPreloader: (state: any, action: any) => {
            return {...state, preloader: action.payload};
        },
    }
})

const {actions, reducer} = site;
export const {setUser, setPreloader} = actions;
export default reducer;