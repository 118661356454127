import React, {Suspense} from 'react';
import Routes from "./Routes";
import * as appActionCreators from "./store/actions/site";
import {connect} from "react-redux";

// import style from "./App.module.scss";

class App extends React.Component {
    render() {
        return (<>
            <Suspense fallback={null}>
                {/*<div className={style.Preloader} id='preloader'>
                    <figure>
                        <picture>
                            <span/>
                        </picture>
                        <small>wait...</small>
                    </figure>
                </div>*/}
                <Routes/>
            </Suspense>
        </>)
    }
}

const mapStateToProps = (state: any) => ({...state.site});
export default connect(mapStateToProps, appActionCreators)(App);