export default {
    'management': 'Gerenciamento',
    'development': 'Desenvolvimento',
    'training': 'Treinamento',

    'contact': 'Contato',
    'partner': 'Parceiros',

    'project_management': 'Gestão de Projetos',
    'tech_artist': 'Artista Técnico',
    'pos_production': 'Pós-Produção',
    'rigging': 'Rigging',

    'full_project': 'Projeto Completo',
    'team_management': 'Gerenciamento de equipe',
    'tech_supervisor': 'Supervisor Técnico',
    'tech_director': 'Diretor Técnico',

    'assembler_project': 'Diretor Técnico',
    'layout_artist': 'Diretor Técnico',
    'tech_anim': 'Diretor Técnico',
    'pipeline': 'Diretor Técnico',

    'composition_3d': 'Composition 3D',
    'composition_2d': 'Composition 2D',
    'composition_live_action': 'Composition Live Action',
    'tracking': 'Tracking',
    'cleanup_footage': 'Cleanup Footage',
    'comp_supervisor': 'Comp Supervisor',

    'character': 'Personagem',
    'prop': 'Prop',
    'vehicle': 'Veículo',
    'creature': 'Criatura',
    'organic': 'Orgânico',
    'tool': 'Ferramenta',

    'alembic_io': 'Publicação de Animação',
    'environment': 'Ambiente Personalizado',
    'scene_management': 'Gestão de Cena',
    'publish_camera': 'Publicação de Camera',
    'management_tools': 'Gestao de Ferramentas',
    'submit_render': 'Render Universal',

    'basic': 'Básico',
    'intermediary': 'Intermédiario',
    'advanced': 'Avançado',
    'registration': 'Cadastre-se',
    'programming': 'Programação',
    'requireds': 'Requisitos',
    'iam': 'Eu sou',
    'studant': 'Estudante',
    'studio': 'Stuúdio',

    'management_info': 'Selecione os campos em que podemos ajudar e em seguida nos submeta.',
    'send':'Enviar',

    'copyright': '© Copyright 2021. Mindblown Lab e clientes.',
    'cookies': 'Este site usa cookies para personalizar anúncios e melhorar sua experiência. De acordo com art. 5º da LGPD (Lei 13.709/2018), solicitamos seu consentimento para armazenar e processar dados pessoais para que as tecnologias deste site funcionem. Sua rejeição pode implicar no não funcionamento das tecnologias deste site. Aceita cookies de sessão no seu navegador?'
}