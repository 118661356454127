import React, {Component} from 'react';
import {connect} from "react-redux";
import * as siteActionCreators from "../../store/actions/site";
import Layout from "../../layout";
import Mindblown from "./mindblown";
import style from "./style.module.scss";
import Message from "./message";
import Emitter from "../../utils/emitter";
import Logo from "../../layout/logo";
import Management from "../Services/management";
import Development from "../Services/development";
import Training from "../Services/training";
import Partners from "../Partners";
import Work from "../Work";

class Page extends Component<any, any> {
    video: any = React.createRef();
    //pages = ['home', 'management', 'development', 'training', 'partner', 'contact'];

    pages = ['home', 'work', 'contact'];

    componentDidMount() {
        Emitter.on('SET_PAGE', (page: string) => {
            this.navigate(page);
        });
    }

    play_video = () => {
        this.video.current.play();
        setTimeout(() => Emitter.emit('animate', null), 800);
    }

    navigate = (page: string) => {
        let get_position_page: any = document.querySelector(`section[data-page="${page}"]`);
        get_position_page.scrollIntoView({behavior: 'smooth'});
    }

    render() {
        return (
            <Mindblown {...this}>
                <Layout {...this.props}>
                    <Logo {...this.props}/>
                    {/*<Message {...this.props}/>*/}
                    <section data-page='home' style={{height: window.innerHeight}}>
                        <video ref={this.video} className={style.Player} loop muted>
                            <source src={require('../../assets/demo.mp4')} type={'video/mp4'}/>
                            <source src={require('../../assets/demo.ogv')} type={'video/ogv'}/>
                            <source src={require('../../assets/demo.webm')} type={'video/webm'}/>
                        </video>
                    </section>
                    {/*<section data-page='management' style={{height: window.innerHeight}}>
                        <Management {...this.props}/>
                    </section>
                    <section data-page='development' style={{height: window.innerHeight}}>
                        <Development {...this.props}/>
                    </section>
                    <section data-page='training' style={{height: window.innerHeight}}>
                        <Training {...this.props}/>
                    </section>
                    <section data-page='partner' style={{height: window.innerHeight}}>
                        <Partners {...this.props}/>
                    </section>*/}
                    <section data-page='work' style={{height: window.innerHeight}}>
                        <Work {...this.props}/>
                    </section>
                    {/*<section data-page='contact' style={{height: window.innerHeight}}>PAGE 5</section>*/}
                </Layout>
            </Mindblown>
        );
    }
}

const mapStateToProps = (state: any) => ({...state.site});
export default connect(mapStateToProps, siteActionCreators)(Page);