import React, {Component} from 'react';
import {connect} from "react-redux";
import * as siteActionCreators from "../../store/actions/site";
import Layout from "../../layout";
import Jobs from "./jobs.json";
import style from "./style.module.scss";
import Emitter from "../../utils/emitter";
import {Language} from "../../component/Translate";

class Page extends Component<any, any> {

    state = {
        player: null
    }

    componentDidMount() {
        let loaded: number = 0;
        let total: number = Jobs.length;
        Jobs.map((job: any) => {
            let image: HTMLImageElement = new Image();
            image.onload = () => {
                loaded++;
                if (Math.floor(loaded * 100 / total) === 100) {
                    Emitter.emit('PRELOAD_OFF', true);
                }
            }
            image.src = require(`../../assets/jobs/${job?.thumb}`);
            return job;
        });
    }

    render() {
        const {player}: any = this.state;
        return (
            <div className={style.MainWork}>
                {player && <div className={style.PlayerBox}>
                    <section className={style.Player}>
                        <div>
                            <span>
                                <strong>{player?.name}</strong>
                                <small>{player?.studio}</small>
                            </span>
                            <small onClick={() => this.setState({player: null})}>Close X</small>
                        </div>

                        {!player?.frame && <video loop autoPlay controls>
                            <source src={player?.video} type={'video/mp4'}/>
                        </video>}

                        {player?.frame && <>
                            <div style={{padding: "56.25% 0 0 0", position: "relative"}}>
                                <iframe src={`${player?.video}?h=3e3ba3e9e5&autoplay=1&title=0&byline=0&portrait=0`} style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <script src="https://player.vimeo.com/api/player.js"></script>
                        </>}

                    </section>
                </div>}
                <div>
                    <ul>
                        {Jobs.map((job: any, index: number) => <li key={index}>
                            <figure>
                                <picture style={{backgroundImage: `url(${require(`../../assets/jobs/${job?.thumb}`)})`}} onClick={() => this.setState({player: job})}>
                                    <span>
                                        <strong>{job?.name}</strong>
                                        <small>{job?.studio}</small>
                                    </span>
                                </picture>
                            </figure>

                        </li>)}
                    </ul>
                </div>
            </div>);
    }
}

const mapStateToProps = (state: any) => ({...state.site});
export default connect(mapStateToProps, siteActionCreators)(Page);