import React, {Component} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';


import Home from './pages/Home';
import Work from './pages/Work';
import About from './pages/About';
import Contact from './pages/Contact';
import Management from './pages/Services/management';
import Developement from './pages/Services/development';
import Training from './pages/Services/training';

import {connect} from "react-redux";
import * as siteActionCreators from "./store/actions/site";

class Routes extends Component<any, any> {
    render() {
        return (
            <>
                <Router>
                    <Switch>
                        <Route path='/' exact component={Home}/>
                        {/*<Route path='/About' exact component={About}/>*/}
                        <Route path='/work' exact component={Work}/>
                        <Route path='/contact' exact component={Contact}/>
                        {/*<Route path='/management' exact component={Management}/>*/}
                        {/*<Route path='/developement' exact component={Developement}/>*/}
                        {/*<Route path='/training' exact component={Training}/>*/}
                    </Switch>
                </Router>
            </>
        );
    }
}


const mapStateToProps = (state: any) => ({...state.site});
export default connect(mapStateToProps, siteActionCreators)(Routes);