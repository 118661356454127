import React from "react";
import * as languages from "../../languages";
import Emitter from "../../utils/emitter";
import {Storage} from "../../utils";

export const translate = (text: string) => {
    //@ts-ignore
    const language: string = global.language || 'pt';
    const get_languages: any = languages;
    if (Object.keys(get_languages).includes(language)) {
        return get_languages[language][text] || text;
    }
    return text;
};

export class Language extends React.Component<any, any> {

    state = {
        //@ts-ignore
        language: Storage.exists('language') || 'en'
    }

    componentDidMount() {
        Emitter.on('SET_LANG', (language: any) => {
            Storage.set('language', language)
            this.setState({language});
        });
    }

    translate = (text: string) => {
        const {language}: any = this.state;
        const get_languages: any = languages;
        if (Object.keys(get_languages).includes(language)) {
            return get_languages[language][text] || text;
        }
        return text;
    };

    render() {
        const {children}: any = this.props;
        return this.translate(children);
    }
}

export default {Language, translate};