import {Storage} from "./index";

export default class Utils {
    static list_schema(field: string) {
        const get_field: any = Storage.exists('schema_fields', null);
        if (get_field) {
            const {properties}: any = get_field[field];
            return properties?.valid_values?.value;
        } else {
            return [];
        }
    }

    static ObjectId = () => {
        let timestamp = (new Date().getTime() / 1000 | 0).toString(16);
        return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => {
            return (Math.random() * 16 | 0).toString(16);
        }).toLowerCase();
    };

    static RandomColor = (rgb: boolean = false) => {
        if (rgb) {
            let r = Math.floor(Math.random() * 256);
            let g = Math.floor(Math.random() * 256);
            let b = Math.floor(Math.random() * 256);
            return `rgb(${r},${g},${b})`;
        } else {
            return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        }
    }

    static get_domain = () => {
        let {host}: any = document.location;
        if (host === 'localhost' || host === '192.168.0.2') {
            return 'mindblow';
        } else {
            return document.location.host.split('.')[0] || host;
        }
    }

    static socket = () => {
        //@ts-ignore
        if (global.socket) {
            //@ts-ignore
            return global.socket
        } else {
            return null;
        }
    }
}