import React, {Component} from 'react';
import style from './menu.module.scss';
import Logo from "./logo";
import Emitter from "../utils/emitter";
import {Language, translate} from "../component/Translate";
import * as languages from "../languages";
import {Storage} from "../utils";

export default class Menu extends Component<any, any> {
    menu: any = React.createRef();

    state = {
        menu: [],
        page: '/',
        opened: false,
        current_lang: Storage.exists('language', 'en')
    }

    time: any = null;

    componentDidMount() {
        const menu: any = [
            // {label: 'management', to: 'management'},
            // {label: 'development', to: 'development'},
            // {label: 'training', to: 'training'},
            // {label: 'partner', to: 'partner'},
            {label: 'work', to: 'work'},
            // {label: 'contact', to: 'contact'},
        ];
        const page: string = document.location.pathname;

        Emitter.on('animate', () => setTimeout(() => this.animate(), 100));
        this.setState({menu, page});
    }

    animate = () => {
        let node_li: any = this.menu?.current.querySelectorAll('li');
        node_li.forEach((node: any, i: number) => {
            node.style.transform = `scale(1)`;
            node.style.opacity = String(1);
            node.style.transitionDuration = `${500}ms)`;
            node.style.transitionDelay = `${100 * i}ms`;
        });
    }

    goto_page = (link: string) => {
        const {page}: any = this.state;
        if (link === page) return;
        if (!this.time) clearTimeout(this.time);
        const preloader: any = document.querySelector('#preloader');
        preloader.style.transitionDuration = '600ms';
        preloader.style.transform = 'translateX(0)';
        preloader.addEventListener('transitionend', (evt: TransitionEvent) => {
            this.props.history.push(link);
        });
    }

    open_close = () => {
        let {opened}: any = this.state;
        opened = !opened;
        this.setState({opened});
    }

    set_lang = (lang: string) => {
        this.setState({current_lang: lang});
        Emitter.emit('SET_LANG', lang);
    }

    navigate = (page: string) => {
        Emitter.emit('SET_PAGE', page);
    }

    render() {
        const {menu, page, opened, current_lang}: any = this.state;
        return (<>
                <section className={style.MainMenu}>
                    <Logo {...this.props}/>
                    <nav data-opened={opened} onMouseLeave={() => this.setState({opened: false})}>
                        <button onClick={() => this.open_close()}>
                            <span/>
                            <span/>
                        </button>
                        <hgroup>
                            {menu.map((link: any, index: number) =>
                                <span key={index} data-active={link?.to === page} onClick={() => this.navigate(link?.to)}><Language>{link?.label}</Language></span>)}
                        </hgroup>
                    </nav>
                    <ul ref={this.menu}>
                        {menu.map((link: any, index: number) =>
                            <li key={index} data-active={link?.to === page} onClick={() => this.navigate(link?.to)}><Language>{link?.label}</Language></li>)}
                        {/*<li>{Object.keys(languages).map((lang: any, idl: any) => <span key={idl} onClick={() => this.set_lang(lang)} data-active={current_lang === lang}>{lang}</span>)}</li>*/}
                    </ul>
                </section>
            </>
        );
    }
}