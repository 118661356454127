import React, {Component} from 'react';
import style from './style.module.scss';
import Menu from "./menu";
import {Storage} from "../utils";
import {Language} from "../component/Translate";

export default class Layout extends Component<any, any> {

    state = {}

    set_cookies = (cookie: boolean) => {
        Storage.set('cookie', cookie);
        this.setState({cookie});
    }

    render() {
        const {children}: any = this.props;
        const {cookie}: any = this.state;
        return (<>
                <section className={style.MainLayout}>
                    <Menu {...this.props}/>
                    <main>
                        {children && children}
                    </main>
                    {cookie === null && <article>
                        <div>
                            <Language>cookies</Language>
                        </div>
                        <hgroup>
                            <button onClick={() => this.set_cookies(true)}>accept</button>
                            <button onClick={() => this.set_cookies(false)}>not accept</button>
                        </hgroup>
                    </article>}
                    <footer>
                        <span className={style.Copyright}><Language>copyright</Language></span>
                    </footer>
                </section>
            </>
        );
    }
}