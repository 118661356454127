import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import siteReduce from "./actions/site";

export default configureStore({
    reducer: {
        site: siteReduce,
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
});