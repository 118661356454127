import React, {Component} from 'react';
import {connect} from "react-redux";
import * as siteActionCreators from "../store/actions/site";
import style from './logo.module.scss'
import Emitter from "../utils/emitter";


class Page extends Component<any, any> {
    logo: any = React.createRef();
    time: any = null;

    componentDidMount() {
        Emitter.on('animate', () => setTimeout(() => this.animate(), 100));
    }

    animate = () => {
        let logo_node: any = this.logo.current;
        logo_node.style.transitionDelay = '200ms';
        logo_node.style.transitionDuration = '500ms';
        logo_node.style.opacity = String(1);
        logo_node.style.transform = 'translateX(0)';
    }

    goto_home = () => {
        Emitter.emit('SET_PAGE', 'home');
    }

    render() {
        return (<div className={style.LogoMain}>
                <picture ref={this.logo} onClick={() => this.goto_home()}>
                    <strong>
                        <span>M</span>
                        <span>B</span>
                    </strong>
                    <label>
                        <span>L</span>
                        <span>a</span>
                        <span>b</span>
                    </label>
                </picture>
            </div>
        );
    }

}

const mapStateToProps = (state: any) => ({...state.site});
export default connect(mapStateToProps, siteActionCreators)(Page);